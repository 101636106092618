<template>
  <div id="manage-ordinateurs">
    <v-container class="lighten-5">
      <v-container style="margin-bottom: 30px">
        <dashboard-core-app-bar />
      </v-container>
      <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>

      <form @submit.prevent="saveProduct">
        <v-row>
          <v-col md="12">
            <v-btn type="submit" class="ma-2" color="primary" dark>
              Register
              <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>General</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" v-if="this.purpose == 'new'">
                      <v-autocomplete
                        :items="productMarks"
                        item-text="Mark"
                        :return-object="true"
                        label="Choisir la marque"
                        hide-details
                        hide-selected
                        required
                        @input="onProductMarksChange"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        :items="productFamily"
                        item-text="family"
                        item-value="familyId"
                        label="Choisir la Famille"
                        clearable
                        hide-details
                        required
                        hide-selected
                        v-model="family"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>

                <v-col cols="12">
                  <v-menu
                    v-model="menuCalendar"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="releaseDate"
                        label="Date de sortie"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @input="menuCalendar = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-text-field
                  label="name"
                  v-model="name"
                  required
                ></v-text-field>
                <v-textarea
                  name="description"
                  label="Description"
                  v-model="description"
                  hint="Hint text"
                ></v-textarea>
                <v-text-field
                  label="Système d'exploitation"
                  v-model="operatingSystem"
                  required
                ></v-text-field>
                <v-text-field
                  label="Dimensions en millimètres"
                  v-model="dimensionInMm"
                  required
                ></v-text-field>
                <v-text-field
                  label="Poids"
                  v-model="weight"
                  required
                ></v-text-field>
                <v-text-field
                  label="Autonomie"
                  v-model="communicationAutonomy"
                  required
                ></v-text-field>
                <v-text-field
                  label="Temps d'attente"
                  v-model="standbyTime"
                  required
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Accessiblity</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-checkbox
                  color="info"
                  hide-details
                  label="Compatibilité auditive"
                  v-model="accessibility.hearingAidCompatibility"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Vibreur"
                  v-model="accessibility.vibrator"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Mains libres"
                  v-model="accessibility.handsFree"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Grandes touches"
                  v-model="accessibility.largeKeys"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Pré enregistrements SMS"
                  v-model="accessibility.preRecordedSMS"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Ecriture SMS intuitive"
                  v-model="accessibility.intuitiveSMSWriting"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Bouton d'appel d'urgence"
                  v-model="accessibility.emergencyCallButton"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Touches programmables"
                  v-model="accessibility.programmableKeys"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Reconnaissance de la parole"
                  v-model="accessibility.speechRecognition"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Mémo vocal"
                  v-model="accessibility.voiceMemo"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Identification de l'appelant par sonnerie"
                  v-model="accessibility.callerIDByRingtone"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Identification de l'appelant par image"
                  v-model="accessibility.callerIDByPhoto"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Functionnality multimedia</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-text-field
                  label="Norme IP"
                  v-model="fonctionnalityAndMultimedia.ipStandard"
                  required
                ></v-text-field>
                <v-checkbox
                  color="info"
                  hide-details
                  label="Internet mobile"
                  v-model="fonctionnalityAndMultimedia.mobileInternet"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="NFC"
                  v-model="fonctionnalityAndMultimedia.nfc"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Email"
                  v-model="fonctionnalityAndMultimedia.email"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Player MP3"
                  v-model="fonctionnalityAndMultimedia.mp3Player"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Radio FM"
                  v-model="fonctionnalityAndMultimedia.fmRadio"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Streaming"
                  v-model="fonctionnalityAndMultimedia.streaming"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Jeux video"
                  v-model="fonctionnalityAndMultimedia.videoGames"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Calculatrice"
                  v-model="fonctionnalityAndMultimedia.calculator"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Alarmes"
                  v-model="fonctionnalityAndMultimedia.alarms"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Contacts"
                  v-model="fonctionnalityAndMultimedia.contacts"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Agendas"
                  v-model="fonctionnalityAndMultimedia.agenda"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Photo and video</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-checkbox
                  color="info"
                  hide-details
                  label="Caméra intégrée"
                  v-model="photoAndVideo.integratedCamera"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Caméra principale"
                  v-model="photoAndVideo.mainCamera"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Caméra secondaire"
                  v-model="photoAndVideo.secondaryCamera"
                />
                <v-text-field
                  v-if="photoAndVideo.integratedCamera"
                  label="Description"
                  v-model="descriptionPhotoAndVideo"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="
                    photoAndVideo.mainCamera && photoAndVideo.integratedCamera
                  "
                  label="Description de la caméra principale"
                  class="materialize-textarea"
                  data-length="500"
                  v-model="photoAndVideo.descriptionMainCamera"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="
                    photoAndVideo.mainCamera && photoAndVideo.integratedCamera
                  "
                  label="Résolution de la caméra principale"
                  v-model="photoAndVideo.resolutionMainCamera"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="
                    photoAndVideo.secondaryCamera &&
                    photoAndVideo.integratedCamera
                  "
                  label="Description de la caméra secondaire"
                  class="materialize-textarea"
                  data-length="500"
                  v-model="photoAndVideo.secondaryCameraDescription"
                  required
                ></v-text-field>

                <v-text-field
                  v-if="
                    photoAndVideo.secondaryCamera &&
                    photoAndVideo.integratedCamera
                  "
                  label="Résolution de la caméra secondaire"
                  v-model="photoAndVideo.secondaryCameraResolution"
                  required
                ></v-text-field>

                <v-checkbox
                  color="info"
                  hide-details
                  label="Flash intégré"
                  v-model="photoAndVideo.BuiltInFlash"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Lecteur vidéo"
                  v-model="photoAndVideo.videoPlayer"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Image MMS"
                  v-model="photoAndVideo.mmsPicture"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Vidéo MMS"
                  v-model="photoAndVideo.mmsVideo"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>DAS (Débit d'absorption spécifique)</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-text-field
                  label="Tête DAS"
                  v-model="das.dasHead"
                  required
                ></v-text-field>
                <v-text-field
                  label="Tronc DAS"
                  v-model="das.dasTrunk"
                  required
                ></v-text-field>
                <v-text-field
                  label="Membres DAS"
                  v-model="das.dasMembers"
                  required
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title
                ><v-checkbox
                  color="info"
                  hide-details
                  label="Avec/sans SIM"
                  v-model="withSim"
                  @change="initializeSim($event)"
              /></v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <div v-if="withSim">
                  <v-text-field
                    label="Format de la carte SIM"
                    v-model="sim.simCardFormat"
                    required
                  ></v-text-field>
                  <v-checkbox
                    color="info"
                    hide-details
                    label="Dual SIM"
                    v-model="sim.dualSIM"
                  />
                </div>
                <div v-else>
                  <v-text-field
                    label="Format de la carte SIM"
                    disabled
                  ></v-text-field>
                  <v-checkbox
                    color="info"
                    hide-details
                    label="Dual SIM"
                    disabled
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Processeur</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-text-field
                  label="Fréquence"
                  v-model="processor.processorFrequency"
                  required
                ></v-text-field>
                <v-text-field
                  label="Cache"
                  v-model="processor.processorCache"
                  required
                ></v-text-field>
                <v-text-field
                  label="Performance"
                  v-model="processor.processorPerformance"
                  required
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Screen</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-text-field
                  label="Taille"
                  v-model="screen.screenSize"
                  required
                ></v-text-field>
                <v-text-field
                  label="Design"
                  v-model="screen.design"
                  required
                ></v-text-field>
                <v-text-field
                  label="Technologie"
                  v-model="screen.screenQualityTechnology"
                  required
                ></v-text-field>
                <v-text-field
                  label="Résolution"
                  v-model="screen.screenResolution"
                  required
                ></v-text-field>
                <v-text-field
                  label="Nombre de couleurs"
                  v-model="screen.numberOfColors"
                  required
                ></v-text-field>
                <v-checkbox
                  color="info"
                  hide-details
                  label="Second écran"
                  v-model="screen.secondScreen"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Écran tactile"
                  v-model="screen.touchScreen"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Memory</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-text-field
                  label="Résolution maximale avec la mémoire installée"
                  v-model="memory.maxResolutionWithInstalledMemory"
                  required
                ></v-text-field>
                <v-text-field
                  label="Mémoire interne nominale"
                  v-model="memory.nominalInternalMemory"
                  required
                ></v-text-field>
                <v-text-field
                  label="Capacité d'extension maximale"
                  v-model="memory.maximumExtensionCapacity"
                  required
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Battery</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-text-field
                  label="Type de batterie"
                  v-model="battery.batteryType"
                  required
                ></v-text-field>
                <v-text-field
                  label="capacité de la batterie"
                  v-model="battery.batteryCapacity"
                  required
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Connectivity</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-text-field
                  label="Communication sans fil"
                  v-model="connectivity.wirelessCommunication"
                  required
                ></v-text-field>
                <v-text-field
                  label="Bande de fréquence"
                  v-model="connectivity.frequecyBand"
                  required
                ></v-text-field>
                <v-checkbox
                  color="info"
                  hide-details
                  label="WIFI"
                  v-model="connectivity.wifi"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="GPS"
                  v-model="connectivity.gps"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Bluetooth"
                  v-model="connectivity.bluetooth"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Compatibilité du chargeur micro USB"
                  v-model="connectivity.microUsbChargerCompatibility"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Synchronisation PC"
                  v-model="connectivity.pcSynchronization"
                />
                <v-checkbox
                  color="info"
                  hide-details
                  label="Connectivité Jack"
                  v-model="connectivity.jackConnectivity"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <!-- ************************************** SHOW CONFIGURATIONS ************************************* -->
          <v-col sm="12">
            <v-col v-for="config in configs" v-bind:key="config.id">
              <ShowConfiguration
                :showBloc="showBloc"
                :objectMark="mark"
                :objectType="type"
                :id="config.id"
                :value="config"
                @input="
                  (newConfiguration) => {
                    config = newConfiguration;
                  }
                "
              />
            </v-col>
          </v-col>

          <!-- **************************************  CONFIGURATIONS ************************************* -->
          <v-col sm="12">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Configurations</v-card-title>
              <v-spacer></v-spacer>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col sm="1">
                    <v-btn
                      @click="addConfiguration"
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      Add a config
                      <v-icon dark right>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-col v-for="config in configs" v-bind:key="config.id">
                  <Configuration
                    :show="show"
                    :objectMark="mark"
                    :objectType="type"
                    :id="config.id"
                    @remove="removeConfig"
                    :value="config"
                    @input="
                      (newConfiguration) => {
                        config = newConfiguration;
                      }
                    "
                  />
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- **************************************  FIN CONFIGURATIONS ************************************* -->
          <!--
          <v-col md="12">
            CONFIGURATION TOTALE JSON =
            <vue-json-pretty :path="'res'" :data="configs"> </vue-json-pretty>
          </v-col>
          -->
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import db from "./../../../../../firebaseInit";
import Configuration from "./../../../components/core/Configuration.vue";
import ShowConfiguration from "./../../../components/core/ShowConfiguration";
import NodesName from "./../../../../utils/nodesName";
import NodesFieldName from "./../../../../utils/nodesFieldName";
import FunctionsUtils from "./../../../../utils/functions";
import FirebaseDB from "./../../../../utils/firebaseDB";
import Constants from "./../../../../utils/constants";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import moment from "moment";

export default {
  name: "manage-mobileDevice",
  components: {
    DashboardCoreAppBar: () =>
      import("../../../../materialDashboard/components/core/AppBar.vue"),
    ShowConfiguration,
    Configuration,
    VueJsonPretty,
  },
  data() {
    return {
      productFamily: [],
      family: null,
      name: null,
      category: null,
      mark: null,
      model: null,
      type: null,
      description: null,
      descriptionPhotoAndVideo: null,
      operatingSystem: null,
      dimensionInMm: null,
      weight: null,
      communicationAutonomy: null,
      standbyTime: null,
      configsEmpty: true,
      documentReferenceMark: null,
      purpose: null,
      product_id: null,
      configs: [],
      images: [],
      productMarks: [],
      show: false,
      showBloc: true,
      date: new Date().toISOString().substr(0, 10),
      menuCalendar: false,
      das: {
        dasHead: null,
        dasTrunk: null,
        dasMembers: null,
      },
      fonctionnalityAndMultimedia: {
        ipStandard: null,
        mobileInternet: false,
        nfc: false,
        email: false,
        mp3Player: false,
        fmRadio: false,
        streaming: false,
        videoGames: false,
        calculator: false,
        alarms: false,
        contacts: false,
        agenda: false,
      },
      photoAndVideo: {
        resolutionMainCamera: null,
        secondaryCameraResolution: null,
        descriptionMainCamera: null,
        secondaryCameraDescription: null,
        mainCamera: false,
        secondaryCamera: false,
        videoPlayer: false,
        integratedCamera: false,
        BuiltInFlash: false,
        mmsPicture: false,
        mmsVideo: false,
      },
      withSim: false,
      sim: {
        simCardFormat: null,
        dualSIM: false,
      },
      accessibility: {
        hearingAidCompatibility: false,
        vibrator: false,
        handsFree: false,
        largeKeys: false,
        preRecordedSMS: false,
        intuitiveSMSWriting: false,
        emergencyCallButton: false,
        programmableKeys: false,
        speechRecognition: false,
        voiceMemo: false,
        callerIDByRingtone: false,
        callerIDByPhoto: false,
      },
      processor: {
        processorFrequency: null,
        processorCache: null,
        processorPerformance: null,
      },
      screen: {
        screenSize: null,
        design: null,
        screenQualityTechnology: null,
        screenResolution: null,
        numberOfColors: null,
        secondScreen: false,
        touchScreen: false,
      },
      memory: {
        maxResolutionWithInstalledMemory: null,
        nominalInternalMemory: null,
        maximumExtensionCapacity: null,
      },
      battery: {
        batteryType: null,
        batteryCapacity: null,
      },
      connectivity: {
        wirelessCommunication: null,
        frequecyBand: null,
        wifi: false,
        gps: false,
        bluetooth: false,
        microUsbChargerCompatibility: false,
        pcSynchronization: false,
        jackConnectivity: false,
      },
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Catalog Settings",
          disabled: false,
          to: "/Mercarue/catalogs/catalogSettings",
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.purpose == Constants.NEW) {
      next((vm) => {
        vm.purpose = to.params.purpose;
        vm.type = to.params.type;
        // Récupérer les marques de produit
        db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
          .where(
            NodesFieldName.SUBCATEGORY,
            "array-contains",
            to.params.subcategory
          )
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              vm.productMarks.push({
                Mark: doc.data().name,
                MarkExternalId: doc.data().externalId,
                MarkId: doc.data().uniqueId,
              });
            });
          });
      });
    } else if (to.params.purpose == Constants.EDIT) {
      db.collection(NodesName.PRODUCT_CATALOG)
        .doc(to.params.mark)
        .collection(to.params.type)
        .doc(to.params.product_id)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            next((vm) => {
              vm.purpose = to.params.purpose;
              vm.type = to.params.type;
              vm.mark = to.params.mark;
              vm.product_id = doc.id;
              vm.name = doc.data().name;
              vm.model = doc.data().model;
              vm.operatingSystem = doc.data().operatingSystem;
              vm.dimensionInMm = doc.data().dimensionInMm;
              vm.weight = doc.data().weight;
              vm.communicationAutonomy = doc.data().communicationAutonomy;
              vm.standbyTime = doc.data().standbyTime;
              vm.description = doc.data().description;
              vm.configs = doc.data().configurations;
              vm.date = moment(
                new Date(doc.data().releaseDate.seconds * 1000)
              ).format("YYYY-MM-DD");
              vm.withSim = doc.data().sim != null;
              vm.sim = doc.data().sim;
              vm.fonctionnalityAndMultimedia =
                doc.data().fonctionnalityAndMultimedia;
              vm.photoAndVideo = doc.data().photoAndVideo;
              vm.descriptionPhotoAndVideo = doc.data().descriptionPhotoAndVideo;
              vm.accessibility = doc.data().accessibility;
              vm.das = doc.data().das;
              (vm.screen = doc.data().screen),
                (vm.processor = doc.data().processor);
              vm.memory = doc.data().memory;
              vm.battery = doc.data().battery;
              vm.connectivity = doc.data().connectivity;
              vm.family = doc.data().family;
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    }
  },
  methods: {
    onProductMarksChange: function (event) {
      this.mark = event.MarkExternalId;
      this.getFamilyList(event.MarkId);
    },

    getFamilyList(event) {
      this.family = null;
      this.productFamily = [];
      if (event) {
        db.collection(NodesName.PRODUCT_TO_SHARE_FAMILY)
          .where(NodesFieldName.MARK, "==", event)
          .where(
            NodesFieldName.SUBCATEGORY,
            "==",
            this.$route.params.subcategory
          )
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.productFamily.push({
                family: doc.data().name,
                familyId: doc.data().uniqueId,
              });
            });
          });
      }
    },

    saveProduct() {
      if (FunctionsUtils.isConfigsEmpty(this.configs)) {
        alert(
          "Configuration color or prices or images is empty, please check configurations !"
        ); //TODO : modifier en snackbars ou toasts;
      } else {
        this.addConfigsInFirebase(this.configs);
      }
    },
    addConfiguration() {
      this.configs.unshift({
        id: FunctionsUtils.create_UUID(),
        color: "",
        images: [],
        prices: [],
      });
      this.show = true;
      this.showBloc = false;
    },
    removeConfig(idToRemove) {
      if (confirm("Are you sure ?")) {
        this.configs = this.configs.filter((config) => {
          return config.id !== idToRemove;
        });
      }
    },
    addConfigsInFirebase(configs) {
      //ajouter l'objet total avec les nouvelles configurations en base de données
      //Ou mise à jour de l'objet existant s'il existe deja
      var FirebaseCatalogRef = db
        .collection(NodesName.PRODUCT_CATALOG)
        .doc(this.mark)
        .collection(this.type)
        .doc();
      if (this.purpose == Constants.NEW) {
        FirebaseCatalogRef.set({
          name: this.name.trim(),
          model: this.mark.trim(),
          mark: this.mark.trim(),
          description: this.description.trim(),
          configurations: configs,
          deleted: false,
          creationDate: new Date(),
          releaseDate: moment(this.date).toDate(),
          uniqueId: FirebaseCatalogRef.id,
          das: this.das,
          fonctionnalityAndMultimedia: this.fonctionnalityAndMultimedia,
          accessibility: this.accessibility,
          photoAndVideo: this.photoAndVideo,
          descriptionPhotoAndVideo:
            this.descriptionPhotoAndVideo != null
              ? this.descriptionPhotoAndVideo.trim()
              : null,
          sim: this.withSim ? this.sim : null,
          operatingSystem: this.operatingSystem,
          dimensionInMm: this.dimensionInMm,
          weight: this.weight,
          communicationAutonomy: this.communicationAutonomy,
          standbyTime: this.standbyTime,
          screen: this.screen,
          processor: this.processor,
          memory: this.memory,
          battery: this.battery,
          connectivity: this.connectivity,
          family: this.family,
        })
          .then(() => {
            this.$router.push({
              name: "catalogSettings",
              params: { mark: this.mark, type: this.type },
            });
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      } else if (this.purpose == Constants.EDIT) {
        db.collection(NodesName.PRODUCT_CATALOG)
          .doc(this.mark)
          .collection(this.type)
          .doc(this.product_id)
          .update({
            name: this.name.trim(),
            model: this.mark.trim(),
            mark: this.mark.trim(),
            description: this.description.trim(),
            configurations: configs,
            deleted: false,
            creationDate: new Date(),
            releaseDate: moment(this.date).toDate(),
            das: this.das,
            fonctionnalityAndMultimedia: this.fonctionnalityAndMultimedia,
            accessibility: this.accessibility,
            photoAndVideo: this.photoAndVideo,
            sim: this.withSim ? this.sim : null,
            operatingSystem: this.operatingSystem,
            dimensionInMm: this.dimensionInMm,
            weight: this.weight,
            communicationAutonomy: this.communicationAutonomy,
            standbyTime: this.standbyTime,
            screen: this.screen,
            processor: this.processor,
            memory: this.memory,
            battery: this.battery,
            connectivity: this.connectivity,
            family: this.family,
          })
          .then(() => {
            this.$router.push({
              name: "catalogSettings",
              params: { mark: this.mark, type: this.type },
            });
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      }
    },
    initializeSim() {
      if (this.withSim) {
        this.sim = { simCardFormat: "", dualSIM: false };
      }
    },
  },
  created() {
    if (this.$route.params.purpose == Constants.EDIT) {
      console.log(this.$route.params.typeId);
      // get MarkID from mark Externel ID(to edit family)
      db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.EXTERNAL_ID, "==", this.$route.params.mark)

        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.collection(NodesName.PRODUCT_TO_SHARE_FAMILY)
              .where(NodesFieldName.MARK, "==", doc.data().uniqueId)
              .where(
                NodesFieldName.SUBCATEGORY,
                "==",
                this.$route.params.typeId
              )
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((ref) => {
                  this.productFamily.push({
                    family: ref.data().name,
                    familyId: ref.data().uniqueId,
                  });
                });
              });
          });
        });
    }
  },
  computed: {
    releaseDate() {
      return FunctionsUtils.formatDate(this.date);
    },
  },
};
</script>
